body {
  --color-1:        rgb(255,255,255);
  --color-2:        rgb(7,7,36);
  --color-3:        rgb(56,56,79);
  --color-4:        rgb(131,131,145);
  --color-5:        rgb(65,158,187);
  --color-6:        rgb(237,162,73);
  --color-7:        rgb(109,46,213);
  --color-8:        rgb(209,76,50);
  --color-9:        rgb(216,58,52);
  --color-10:       rgb(205,81,32);
  --color-11:       rgb(30,193,162);
  --color-12:       rgb(45,104,240);
  --color-Mercury:  rgb(222,224,252);
  --color-Venus:    rgb(247,204,127);
  --color-Earth:    rgb(84,91,254);
  --color-Mars:     rgb(255,106,69);
  --color-Jupiter:  rgb(236,173,122);
  --color-Saturn:   rgb(252,203,207);
  --color-Uranus:   rgb(101,240,213);
  --color-Neptune:  rgb(73,126,250);

}

.layout__main {
  background-position: center;
	background-size: cover;
  position: relative;
  min-height: 100vh;
  max-width: 100vw;
  background-color: var(--color-2);
  color: var(--color-1);
  padding: 1rem;
}