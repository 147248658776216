#navbar {
	position: relative;
	height: 68px;
	justify-content: center;
	/* padding: 17px 24px; */
	border-bottom: 1px solid var(--color-4);
}

#navbar__logo {
	font-family: 'Antonio', sans-serif;
	font-size: 28px;
	font-weight: 500;
	text-transform: uppercase;
	text-decoration: none;
	color: var(--color-1);
	justify-self: start;
	align-self: center;
	cursor: pointer;
}

.nav__links {
	display: flex;
	text-transform: uppercase;
	text-decoration: none;
	list-style: none;
	color: var(--color-1);
	justify-content: space-between;
	align-items: center;
	padding: 1rem 1.5rem;
	width: 95vw;
	transition: all .8s ease-in-out;
	border-bottom: 4px solid transparent;
}

.nav__menu {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;
	height: 89vh;
	position: absolute;
	top: 69px;
	transform: translateX(-110%);
	opacity: 1;
	align-items: center;
	padding-top: 1rem;
}

.nav__menu.active {
	display: flex;
	width: 100%;
	justify-content: start;
	z-index: 1000;
	transform: translateX(0);
	background-color: black;
	left: 0;
	opacity: 1;
	transition: all 0.5s ease-out;
	padding: 0 1rem;
}

.nav__item {
	display: flex;
	width: 100%;
	justify-content: space-between;
	/* align-items: center; */
	height: 60px;
	border-bottom: 1px solid var(--color-4);
}

.nav__links span {
	display: flex;
	flex-direction: column;
	font-size: 2rem;

}

.planet__marker {
	display:flex;
	justify-content:start;
	align-items: center;
}
.circle {
	margin-right: 1rem;
	width: 20px;
	height: 20px;
	display: inline-block;
	border-radius: 50%;
}

.circle.mercury {
	background-color: var(--color-Mercury);
}
.circle.venus {
	background-color: var(--color-Venus);
}
.circle.earth {
	background-color: var(--color-Earth);
}
.circle.mars {
	background-color: var(--color-Mars);
}
.circle.jupiter {
	background-color: var(--color-Jupiter);
}
.circle.saturn {
	background-color: var(--color-Saturn);
}
.circle.uranus {
	background-color: var(--color-Uranus);
}
.circle.neptune {
	background-color: var(--color-Neptune);
}

#menu__icon {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	/* transform: translate(-70%, 50%); */
	font-size: 2rem;
	cursor: pointer;
}

@media only screen and (min-width: 891px) {
		/* Planet Hover Effects */
	#mercury:hover {
		border-bottom: 4px solid var(--color-Mercury);
	}
	#mercury.selected{
		border-bottom: 4px solid var(--color-Mercury);
	}
	#venus:hover {
		border-bottom: 4px solid var(--color-Venus);
	}
		#venus.selected{
		border-bottom: 4px solid var(--color-Venus);
	}
	#earth:hover {
		border-bottom: 4px solid var(--color-Earth);
	}
		#earth.selected{
		border-bottom: 4px solid var(--color-Earth);
	}
	#mars:hover {
		border-bottom: 4px solid var(--color-Mars);
	}
		#mars.selected{
		border-bottom: 4px solid var(--color-Mars);
	}
	#jupiter:hover {
		border-bottom: 4px solid var(--color-Jupiter);
	}
		#jupiter.selected{
		border-bottom: 4px solid var(--color-Jupiter);
	}
	#saturn:hover {
		border-bottom: 4px solid var(--color-Saturn);
	}
		#saturn.selected{
		border-bottom: 4px solid var(--color-Saturn);
	}
	#uranus:hover {
		border-bottom: 4px solid var(--color-Uranus);
	}
		#uranus.selected{
		border-bottom: 4px solid var(--color-Uranus);
	}
	#neptune:hover {
		border-bottom: 4px solid var(--color-Neptune);
	}
		#neptune.selected{
		border-bottom: 4px solid var(--color-Neptune);
	}
	#navbar {
		display: flex;
		flex-direction: column;
		border-bottom: none;
		text-align: center;
	}

	.nav__menu {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 72px;
		bottom: 0;
		padding-bottom: 2rem;
		transform: translateX(0%);
		border-bottom: 1px solid grey;
	}

	.nav__item {
		border-bottom: none;
		width: 100%;
		font-size: 0.86rem;
		font-weight: 300;
		letter-spacing: 1px;
	}
	.nav__links {
		display:block;
		width: 100%;
	
	}
	#menu__icon {
		display: none;
	}

	.nav__menu.active {
		display: none;
	}

}

/* START DESKTOP VIEW */
@media only screen and (min-width: 1189px) {
	#navbar {
		position: relative;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.nav__menu {
		position: relative;
		width: 100%;
		justify-content: flex-start;
		align-items: center;
		height: 68px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 2rem;
		border-bottom: none;
		margin: 1rem;
	}

	#navbar__logo {
		margin-left: 1rem;
	}
}
