#planet__wrapper {
	display: flex;
	flex-direction: column;
	z-index: -1;
}

#planet__content__toolbar__wrapper {
	border-bottom: 1px solid grey;
	height: 50px;
}

#planet__content__toolbar {
	display: flex;
	justify-content: space-evenly;
	align-items: stretch;
	list-style: none;
	max-height: 100%;
	padding-top: 1rem;
}

#planet__content__toolbar li {
	border-bottom: 2px solid transparent;
	height: 100%;
	width: 100%;
}

/* Planet images */
#planet__img {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 225px;
	width: 100%;
	margin-bottom: 2rem;
}

#planet__img img {
	width: 173px;
	height: 173px;
	text-align: center;
}

#summary__surface__container {
	position: relative;
}
#surface__img {
	position: absolute;
	max-width: 125px;
	max-height: 125px;
	left: 15%;
	top: 55%;
}

#planet__content {
	max-width: 327px;
	min-height: 235px;
	margin: 0 auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#planet__content h1 {
	font-family: 'Antonio', sans-serif;
	text-transform: uppercase;
	font-size: 40px;
}

#facts {
	flex-direction: column;
	justify-content: space-between;
	min-width: 20rem;
	min-height: 15rem;
	margin: 2rem auto;
}
.planet__facts {
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	width: 100%;
	height: 48px;
	border: 1px solid var(--color-4);
}

#radio__toolbar {
	border-bottom: 1px solid var(--color-4);
	justify-content: space-between;
	width: 100%;
}

/* Remove the radio button */
#radio__toolbar input[type='radio'] {
	opacity: 0;
	position: fixed;
	width: 0;
	display: inline-block;
}

/* Style radio button label */
#radio__toolbar label {
	display: inline-block;
	padding: 10px;
	border-bottom: 5px solid transparent;
	cursor: pointer;
	font-weight: 700;
}

/* Add bottom border to label on focus based on Planet selected */
#radio__toolbar input[type='radio']:focus + label.Mercury {
	border-bottom: 5px solid var(--Mercury);
}
#radio__toolbar input[type='radio']:focus + label.Venus {
	border-bottom: 5px solid var(--Venus);
}
#radio__toolbar input[type='radio']:focus + label.Earth {
	border-bottom: 5px solid var(--Earth);
}
#radio__toolbar input[type='radio']:focus + label.Mars {
	border-bottom: 5px solid var(--Mars);
}
#radio__toolbar input[type='radio']:focus + label.Jupiter {
	border-bottom: 5px solid var(--Jupiter);
}
#radio__toolbar input[type='radio']:focus + label.Saturn {
	border-bottom: 5px solid var(--Saturn);
}
#radio__toolbar input[type='radio']:focus + label.Uranus {
	border-bottom: 5px solid var(--Uranus);
}
#radio__toolbar input[type='radio']:focus + label.Neptune {
	border-bottom: 5px solid var(--Neptune);
}

/* Add hover effect for radio labels bosed on Planet (using classes) */

#radio__toolbar label.Mercury:hover {
	border-bottom: 5px solid var(--color-Mercury);
}
#radio__toolbar label.Venus:hover {
	border-bottom: 5px solid var(--color-Venus);
}
#radio__toolbar label.Earth:hover {
	border-bottom: 5px solid var(--color-Earth);
}
#radio__toolbar label.Mars:hover {
	border-bottom: 5px solid var(--color-Mars);
}
#radio__toolbar label.Jupiter:hover {
	border-bottom: 5px solid var(--color-Jupiter);
}
#radio__toolbar label.Saturn:hover {
	border-bottom: 5px solid var(--color-Saturn);
}
#radio__toolbar label.Uranus:hover {
	border-bottom: 5px solid var(--color-Uranus);
}
#radio__toolbar label.Neptune:hover {
	border-bottom: 5px solid var(--color-Neptune);
}

/* Add bottom border on selected based on Planet (using classes) */
#radio__toolbar input[type='radio']:checked + label.Mercury {
	border-bottom: 5px solid var(--color-Mercury);
}
#radio__toolbar input[type='radio']:checked + label.Venus {
	border-bottom: 5px solid var(--color-Venus);
}
#radio__toolbar input[type='radio']:checked + label.Earth {
	border-bottom: 5px solid var(--color-Earth);
}
#radio__toolbar input[type='radio']:checked + label.Mars {
	border-bottom: 5px solid var(--color-Mars);
}
#radio__toolbar input[type='radio']:checked + label.Jupiter {
	border-bottom: 5px solid var(--color-Jupiter);
}
#radio__toolbar input[type='radio']:checked + label.Saturn {
	border-bottom: 5px solid var(--color-Saturn);
}
#radio__toolbar input[type='radio']:checked + label.Uranus {
	border-bottom: 5px solid var(--color-Uranus);
}
#radio__toolbar input[type='radio']:checked + label.Neptune {
	border-bottom: 5px solid var(--color-Neptune);
}

/* SHARED CLASSES */
.flex {
	display: flex;
}

/* Regular Link styles */
.link:link {
	color: rgb(198, 202, 202);
	font-weight: 800;
}
.link:visited {
	color: grey;
}
.link:hover {
	color: white;
}

/* Create Fade effect for images */

.fade-in {
	animation: fadeIn ease 1s;
	-webkit-animation: fadeIn ease 1s;
	-moz-animation: fadeIn ease 1s;
	-o-animation: fadeIn ease 1s;
	-ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media only screen and (min-width: 891px) {
	#planet__wrapper {
		padding: 9rem 1rem 0rem;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		z-index: -1;
		max-width: 900px;
		margin: 0 auto;
	}

	#planet__img {
		order: 1;
		height: 10rem;
		margin-bottom: 6rem;
	}

	#planet__img img {
		width: 14rem;
		height: 14rem;
		text-align: center;
	}

	#surface__img {
		position: absolute;
		max-width: 7rem;
		max-height: 7rem;
		left: 25%;
		top: 60%;
	}

	#planet__content {
		order: 2;
		width: 327px;
		min-height: 327px;
		margin: 0.1rem;
		text-align: left;
		flex-direction: column;
		justify-content: space-evenly;
		font-family: 'Spartan', sans-serif;
		font-size: 1rem;
		line-height: 22px;
		flex-basis: 40%;
		align-items: baseline;
	}

	#planet__content h1 {
		font-family: 'Antonio', sans-serif;
		text-transform: uppercase;
		font-size: 48px;
		font-weight: 400;
		margin-bottom: 2rem;
	}

	#planet__content p {
		line-height: 1.8rem;
	}

	#radio__toolbar {
		flex-direction: column;
		order: 3;
		border-bottom: none;
		align-items: flex-end;
		justify-content: space-evenly;
		min-height: 18rem;
		max-width: 30rem;
	}

	#radio__toolbar label {
		width: 100%;
		height: 100%;
		border-bottom: 5px solid transparent;
		padding: 1rem;
	}

	/* Add bottom border on selected based on Planet (using classes) */

	#radio__toolbar input[type='radio'] + label {
		font-size: 1.1rem;
	}

	#radio__toolbar input[type='radio']:checked + label.Mercury {
		background-color: var(--color-Mercury);
		color: black;
		font-weight: 700;
	}
	#radio__toolbar input[type='radio']:checked + label.Venus {
		background-color: var(--color-Venus);
		color: black;
	}
	#radio__toolbar input[type='radio']:checked + label.Earth {
		background-color: var(--color-Earth);
	}
	#radio__toolbar input[type='radio']:checked + label.Mars {
		background-color: var(--color-Mars);
	}
	#radio__toolbar input[type='radio']:checked + label.Jupiter {
		background-color: var(--color-Jupiter);
		color: black;
	}
	#radio__toolbar input[type='radio']:checked + label.Saturn {
		background-color: var(--color-Saturn);
		color: black;
	}
	#radio__toolbar input[type='radio']:checked + label.Uranus {
		background-color: var(--color-Uranus);
		color: black;
	}
	#radio__toolbar input[type='radio']:checked + label.Neptune {
		background-color: var(--color-Neptune);
	}

	.categorySelector {
		border: 1px solid var(--color-4);
		width: 75%;
		text-align: center;
	}

	#facts.flex {
		width: 100%;
		max-height: 5rem;
		order: 4;
		flex-direction: row;
		justify-content: space-between;
	}

	.planet__facts {
		max-width: 10rem;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		height: 6rem;
		border: 1px solid var(--color-4);
	}
	.planet__facts p {
		font-family: 'Spartan', sans-serif;
		color: var(--color-4);
		font-size: 0.75rem;
		text-transform: uppercase;
	}
	.planet__facts span {
		font-family: 'Antonio', sans-serif;
		color: var(--color-1);
		font-size: 1.6rem;
	}
}

@media only screen and (min-width: 1189px) {
	#planet__wrapper {
		padding: 4rem 2rem 0rem;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		max-width: 100%;
		/* height: 100%; */
		z-index: -1;
	}

	#planet__img {
		order: 1;
		height: 40rem;
		margin: auto;
		width: 40rem;
	}

	#planet__img img {
		width: 100%;
		height: 100%;
	}

	#surface__img {
		position: absolute;
		max-width: 15rem;
		max-height: 15rem;
		left: 25%;
		top: 60%;
	}

	#planet__content {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 50%;
		max-width: 350px;
		margin-right: 2rem;
		justify-content: space-evenly;
	}

	#planet__content h1 {
		font-size: 80px;
		margin-bottom: 3rem;
	}

	#planet__content p {
		font-size: 14px;
	}

	#radio__toolbar {
		position: absolute;
		right: 5rem;
		top: 27rem;
		margin: 2rem auto;
		height: 15rem;

	}

	#radio__toolbar label {
		font-size: 1.2rem;
	}

	#facts.flex {
		height: 8rem;
	}

	.planet__facts.flex {
		max-width: 22.5rem;
		height: 8rem;
		margin: 2rem;
	}
	.planet__facts p {
		font-size: 1rem;
	}
	.planet__facts span {
		font-family: 'Antonio', sans-serif;
		color: var(--color-1);
		font-size: 2.5rem;
	}
}
